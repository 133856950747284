.footer-footer {
  border-color: rgba(33, 33, 33, 0.1);
  border-width: 1px;
  background-color: var(--dl-color-grays-white80);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}

.footer-max-width {
  align-items: flex-start;
  justify-content: space-between;
}

.footer-container {
  flex: 0 0 auto;
  width: 33%;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.footer-container .title {
  padding-left: 0;
}

.footer-text01 {
  color: var(--dl-color-grays-gray100);
  max-width: 350px;
  margin-bottom: 60px;
}

.footer-social-media {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.footer-text04 {
  font-style: normal;
  margin-bottom: 0px;
}

.footer-container1 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.footer-icon {
  width: 24px;
  height: 24px;
  transition: 0.3s;
  margin-right: var(--dl-space-space-twounits);
}

.footer-icon:hover {
  fill: var(--dl-color-backgrounds-primary);
  cursor: pointer;
}

.footer-icon4 {
  width: 24px;
  height: 24px;
  transition: 0.3s;
}

.footer-icon4:hover {
  fill: var(--dl-color-backgrounds-primary);
  cursor: pointer;
}

.footer-container2 {
  flex: 0 0 auto;
  width: 33%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.footer-text08 {
  color: var(--dl-color-grays-gray100);
  max-width: 350px;
  margin-bottom: 60px;
}

.footer-text20 {
  color: var(--dl-color-grays-gray100);
  max-width: 350px;
  margin-bottom: 0px;
}

@media(max-width: 991px) {
  .footer-max-width {
    flex-direction: column-reverse;
  }

  .footer-container {
    width: 100%;
  }

  .footer-container2 {
    width: 100%;
  }
}

@media(max-width: 479px) {
  .footer-max-width {
    align-items: flex-start;
  }
}