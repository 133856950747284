.showme {
    display: none !important;
}

.showchild:hover .showme {
    display: flex !important;
    width: 80%;
    cursor: pointer;
    margin: 0px !important;
    padding: 0px !important;
    height: 1em;
}


.showchild {
    width: 100%;
    height: 1em;
    border: 1px;
    background-color: rgb(200, 198, 198);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}