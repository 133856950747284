a.active, a.inactive {
    display: block;
    padding: 2.5px 0px;
}

a.active {
    background-color: #7c909b;
    opacity: 1;
    pointer-events: none;
}

a.active :hover * {
    opacity: 1
}

a.inactive {
    opacity: 0.8;
    pointer-events: 'none'
}

a.inactive :hover {
    background-color: var(--dl-color-backgrounds-endurusblue);
    opacity: 1
}