.input-container-input-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: row;
}

.input-container-container {
  width: 100%;
  height: 56px;
  display: flex;
  max-width: 376px;
  align-items: center;
  line-height: 1.5;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  margin-right: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
}

.input-container-input {
  flex: 1;
  color: var(--dl-color-grays-dark100);
  border-width: 0px;
  padding-left: 12px;
  padding-right: 12px;
}

.input-container-button {
  display: flex;
  transition: 0.3s;
  align-items: center;
  line-height: 1;
  padding-top: 20px;
  white-space: nowrap;
  padding-left: 32px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 32px;
  padding-bottom: 20px;
  justify-content: center;
  background-color: var(--dl-color-backgrounds-endurusblue);
}

.input-container-button:hover {
  color: var(--dl-color-grays-white80);
  border-color: var(--dl-color-backgrounds-endurusblue);
  background-color: var(--dl-color-backgrounds-primaryred);
}

@media(max-width: 479px) {
  .input-container-input-container {
    flex-direction: column;
  }

  .input-container-container {
    max-width: 100%;
    margin-bottom: var(--dl-space-space-unit);
  }
}