.signup-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
}

.signup-signup {
    width: 100%;
    height: 100%;
    display: flex;
    min-height: 100vh;
    justify-content: center;
    flex-direction: column;
}

.signup-container-1 {
    padding-top: 128px;
    background-color: #f5f5f5ff;
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-threeunits);
    justify-content: center;
    padding-top: 256px;
    padding-bottom: 128px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    /* align-items: center; */
    padding-left: 30vw;
}


.signup-button {
    color: var(--dl-color-scheme-white);
    margin-top: auto;
    padding-top: var(--dl-space-space-unit);
    border-width: 0px;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
    background-color: var(--dl-color-scheme-black);
}

.loggin-button:hover {
    color: var(--dl-color-scheme-lightblue)
}