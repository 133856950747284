.navbar-navbar-container {
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-grays-white80);
}

.navbar-max-width {
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
}

.navbar-text {
  font-style: normal;
}

.navbar-nav {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
}

.navbar-burger-menu {
  border: 2px dashed rgba(120, 120, 120, 0.4);
}

.navbar-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.navbar-container {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}

.navbar-image {
  width: 106px;
}

.navbar-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.navbar-primary-btn {
  margin-top: var(--dl-space-space-oneandhalfunits);
}

.navbar-social-media {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.navbar-text1 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.navbar-container1 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.navbar-icon02 {
  width: 24px;
  height: 24px;
  transition: 0.3s;
  margin-right: var(--dl-space-space-twounits);
}

.navbar-icon02:hover {
  fill: var(--dl-color-backgrounds-primary);
  cursor: pointer;
}

.navbar-icon06 {
  width: 24px;
  height: 24px;
  transition: 0.3s;
  margin-right: var(--dl-space-space-twounits);
}

.navbar-icon06:hover {
  fill: var(--dl-color-backgrounds-primary);
  cursor: pointer;
}

.navbar-icon10 {
  width: 24px;
  height: 24px;
  transition: 0.3s;
  margin-right: var(--dl-space-space-twounits);
}

.navbar-icon10:hover {
  fill: var(--dl-color-backgrounds-primary);
  cursor: pointer;
}

.navbar-icon12 {
  width: 24px;
  height: 24px;
  transition: 0.3s;
}

.navbar-icon12:hover {
  fill: var(--dl-color-backgrounds-primary);
  cursor: pointer;
}

.navigation-container {
  padding: 10px;
}

.logo {
  width: 180px;
  max-height: 200px;
  margin: 20px 50px;
}


@media(max-width: 1100px) {
  .logo {
    width: 100px;
    max-height: 70px;
    margin: 10px;
  }
}

@media(max-width: 767px) {
  .navbar-nav {
    display: none;
  } 
  .navbar-burger-menu {
    display: none;
  }
  .logo {
    width: 100px;
    max-height: 70px;
    margin: 10px;
  }
}

@media(max-width: 500px) {
  .logo {
    width: 100px;
    max-height: 70px;
    margin: 10px;
  }
}