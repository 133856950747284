.home-container {
  width: 100%;
  display: flex;
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-backgrounds-lightblue);
}

.home-main {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.home-max-width {
  align-items: stretch;
}

.home-content-container {
  flex: 1;
  display: flex;
  min-width: 50%;
  align-items: flex-start;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}

.home-text {
  width: 504px;
  margin-bottom: 42px;
}

.home-text2 {
  color: var(--dl-color-backgrounds-primaryred);
}

.home-features-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}

.home-text3 {
  font-style: normal;
  font-weight: 300;
}

.home-text5 {
  color: var(--dl-color-backgrounds-green);
}

.home-text7 {
  color: var(--dl-color-backgrounds-green);
}

.home-image-container {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.home-image {
  width: 500px;
  max-width: 550px;
  object-fit: cover;
  margin-left: 45px;
  border-radius: var(--dl-radius-radius-radius8);
}

@media(max-width: 991px) {
  .home-max-width {
    flex-direction: column;
  }

  .home-content-container {
    margin-bottom: 42px;
    padding-right: 0px;
  }

  .home-image {
    max-width: 100%;
    margin-left: 0px;
  }
}

@media(max-width: 479px) {
  .home-text {
    width: 100%;
  }
}