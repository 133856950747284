.CardLink{
    flex-basis: 100%;
    margin-bottom: 25px;
    min-width: 80px;
}

@media (min-width: 400px) {
    .CardLink {
        flex-basis: calc(50% - 20px);
        margin-right: 20px;
    }
}
@media (min-width: 800px) {
    .CardLink {
        flex-basis: calc(33% - 20px);
        margin-right: 20px;
    }
} 
@media (min-width: 1200px) {
    .CardLink {
        flex-basis: calc(25% - 20px);
        margin-right: 20px;
    }
} 